import * as React from "react"
import { Section, Button, Card, Container } from "@bw/bits"
import { Hero, Columns, Content } from "@bw/modules"
import { Newsletter } from "@bw/partials"
import { Layout } from "@bw/layouts"
import { useTranslation } from "react-i18next"
import data from "../partenaires.json"

const ListPartenaires = data
  .sort((a, b) => a.name.localeCompare(b.name))
  .map((partenaire, index) => {
    return (
      <Card type="text" direction="left" key={index}>
        <address>
          <h3>{partenaire.name}</h3>
          {partenaire.adress} <br />
          {partenaire.city}
        </address>
        <p>
          <strong>Dans notre assortiment:</strong>
          <br />
          {partenaire.assortment}
        </p>
      </Card>
    )
  })

const IndexPage = () => {
  const { t } = useTranslation()
  const partenaireAnchorRef = React.useRef(null)
  return (
    <Layout>
      <Hero
        suptitle={t("Liste")}
        title={t("des partenaires")}
        background="var(--primary)"
        button={
          <Button
            primary
            label={t("Devenir partenaire")}
            inversed
            to="#partenaire"
          />
        }
        contentSize="490px"
      />
      <Section padding="45px 0" pattern>
        <Columns count={2}>{ListPartenaires}</Columns>
      </Section>
      <Section
        id="partenaire"
        suptitle="Devenir"
        title="partenaire"
        padding="96px 0 44px"
        background="var(--grey)"
        decoration
        ref={partenaireAnchorRef}
      >
        <Columns count={2}>
          <Content>
            <p>Chez l’Epicier, nous misons sur le partenariat !</p>
            <p>
              Vous êtes producteur, un artisan, une association ou encore un
              prestataire de services et vous vous intégrez dans une ou
              plusieurs de nos valeurs ?
            </p>
          </Content>
          <Content>
            <p>
              Nous sommes à votre disposition et vous répondrons volontiers par
              téléphone ou par e-mail. Plus simple encore, passez nous voir !
            </p>
            <p>
              Téléphone: <a href="tel:+41786154575">078 615 45 75</a>
              <br />
              Email:{" "}
              <a href="mailto:contact@chezlepicier.ch">
                contact@chezlepicier.ch
              </a>
            </p>
          </Content>
        </Columns>
      </Section>
      <Section>
        <Container>
          <Newsletter />
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
